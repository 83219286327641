@import url('https://fonts.cdnfonts.com/css/bdo-grotesk');

@import 'bootstrap/scss/functions';

$BDO-font-family: 'Raleway', sans-serif;
$primary: #8a50fc;
$secondary: rgba(9, 0, 25, 1);
$light-primary: rgba(238, 230, 255, 1);
$prefix: 'tj-';
$enable-negative-margins: true;
$btn-padding-y: 1em;
$btn-padding-x: 2rem;
$btn-font-weight: 500;
$btn-font-size: 15px;
$btn-border-radius: 0.5rem;
$nav-link-color: #555;
$nav-link-hover-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$headings-font-family: $BDO-font-family;
$headings-font-style: normal;
$headings-font-weight: 600;
$headings-color: #1f1f1f;
$font-family-base: $BDO-font-family;
// $body-bg: #F8F5FF;
$accordion-bg: #fff;
$accordion-button-bg: #fff;
$accordion-button-active-bg: #fff;
$accordion-button-active-color: #{$primary};
$accordion-border-radius: 1rem;
$accordion-border-color: transparent;
$accordion-button-icon: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>');
$accordion-button-active-color: #{$primary};
$accordion-button-active-icon: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-dash-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/></svg>');
$accordion-button-focus-box-shadow: none !important;
$accordion-padding-x: 16px;
$accordion-padding-y: 24px;
$table-bg: transparent;

$nav-underline-link-active-color: $primary;

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
$taoja-theme-colors: (
  'taoja-light-primary': $light-primary,
);
$colors: map-merge($colors, $taoja-theme-colors);
$theme-colors: map-merge($theme-colors, $taoja-theme-colors);

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';
// scss-docs-end import-stack

body {
  --tj-body-font-weight: 400
}

footer {
  .nav-link {
    --tj-nav-link-color: rgba(49, 45, 57, 1);
    font-size: 15px;
  }

  --tj-heading-color: rgba(49, 45, 57, 1);
}
.wrapper {
  background: linear-gradient(
    180deg,
    rgba(197.36, 102.17, 233.58, 0.1) 0%,
    rgba(227.51, 210.59, 248.41, 0.1) 100%
  );
}

// testing new style
.wrapper {
  padding: 5rem 70px;
  margin-top: -10px;
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  width: 100vw;
  overflow: hidden !important;
  padding-top: 3rem !important;

  .left-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 3rem;
    .title1 {
      font-weight: 700;
    }
    .bttn {
      width: fit-content;
    }
  }

  .right-container {
    margin-left: auto;
    margin-bottom: 5rem;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .floatingimg {
    left: 36%;
    top: 54%;
  }

  .google {
    top: 25%;
    left: 51%;
  }

  .logos_pinterest {
    right: 37%;
    bottom: 22%;
  }
  .bttn-mobile,
  .img2b,
  .instagram2,
  .google2,
  .facebook2,
  .logos_pinterest2 {
    display: none;
  }
}
@media screen and (max-width: 884px) {
  .wrapper {
    .hero-mobile {
      display: flex;
      align-items: center;
      margin: auto;
    }

    .right-container {
      margin-left: -10rem;

      img {
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .tablet {
      margin-left: 7rem !important;
    }

    .floatingimg {
      left: 30%;
      top: 80%;
      width: 350px;
    }
    .hiddenImages {
      display: none;
    }

    .facebook {
      top: 44%;
      left: 64%;
    }
    .google {
      top: 42%;
      left: 14%;
    }
    .instagram {
      left: 62%;
      bottom: 12%;
    }
    .logos_pinterest {
      right: 66%;
      bottom: 15%;
    }
  }
  .g2 {
    margin-top: -7rem;
  }
}
@media screen and (max-width: 820px) {
  .wrapper {
    padding: 0 0px;

    padding-bottom: 10rem;
    text-align: center;
    align-items: center;
    .hero-mobile {
      display: flex;
      margin: auto;
    }
    .hero-card-title {
      font-size: 40px;
      line-height: 42px;
      padding-bottom: 5px !important;
      text-align: center;
      margin-top: -40px;
    }
    .hero-card-body {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-top: -10px;
    }

    .bttn {
      margin-top: 0.2rem;
    }

    .right-container {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .tablet {
      margin-left: 10rem !important;
    }

    .floatingimg {
      left: 30%;
      top: 83%;
      width: 300px;
    }
    .hiddenImages,
    .img2b,
    .instagram,
    .google,
    .logos_pinterest,
    .facebook {
      display: none;
    }

    .img2,
    .google2,
    .instagram2,
    .facebook2,
    .logos_pinterest2 {
      display: grid;
    }
    .bttn {
      text-align: center;
      width: 200px;
      margin: auto;
    }

    .facebook2 {
      top: 42%;
      left: 62%;
    }
    .instagram2 {
      top: 42%;
      left: 16%;
    }
    .google2 {
      bottom: 12%;
      left: 58%;
    }
    .logos_pinterest2 {
      bottom: 14%;
      left: 19%;
    }
  }
  .g2 {
    margin-top: -7rem;
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    padding: 0 0px;
    padding-bottom: 10rem;
    text-align: center;
    align-items: center;

    .tablet {
      margin-left: 0px !important;
    }
    .floatingimg {
      left: 25%;
      top: 77%;
      width: 350px;
    }
    .bttn,
    .hiddenImages,
    .img2,
    .instagram,
    .google,
    .logos_pinterest,
    .facebook {
      display: none;
    }
    .img2b,
    .google2,
    .instagram2,
    .facebook2,
    .bttn-mobile,
    .logos_pinterest2 {
      display: grid;
    }
    .bttn,
    .bttn-mobile {
      text-align: center;
      width: 200px;
      margin: auto;
      // margin-top: 10px;
    }

    .facebook2 {
      top: 41%;
      left: 72%;
    }
    .instagram2 {
      top: 41%;
      left: 16%;
    }
    .google2 {
      left: 70%;
      bottom: 11%;
    }
    .logos_pinterest2 {
      bottom: 12%;
      left: 16%;
    }
  }
  .g2 {
    margin-top: -7rem;
  }
}
@media screen and (max-width: 500px) {
  .wrapper {
    .right-container {
      margin-left: 10px;
      img {
        width: 100%;
      }
    }

    .floatingimg {
      width: 200px;
    }
  }

  .hero-h1 {
    font-size: 40px !important;


  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1370px;
  }
}

@media all and (min-width: 992px) {
  .navbar {
    background-color: #fcfaff !important;

    .nav-item {
      .dropdown-menu {
        //min-width: 350px;
        //max-width: 400px;
        display: none;
        margin-top: 0;
        box-shadow: $box-shadow;
        border-color: #ffffff;
        padding: 1rem 0.5rem;
        border-radius: $border-radius-sm;
        background-color: #ffffff;
      }

      &:hover .dropdown-menu {
        display: block;
        &:has(.col-lg-6) {
          display: flex;
          flex-flow: row;
          gap: 0.5rem;
          min-width: 750px;
          margin-left: -10vh;
          & > * {
            text-wrap: normal;
          }
        }

        &:hover .dropdown-item {
          background: none;
        }

        .dropdown-item.active {
          background: none;
          color: #1f1f1f;
        }
      }
    }
  }

  .wrapper {
    .instagram {
      bottom: 16%;
      left: 84%;
    }
    .facebook {
      left: 86%;
      top: 20%;
    }
    .floatingimg {
      width: 450px;
      top: 70%;
      max-height: 200px;
    }
    .floatingimg2 {
      // width: 400px;
      height: 200px;
    }
  }
}

@media all and (min-width: 1281px) {
  .wrapper {
    .instagram {
      left: 75%;
      bottom: 14%;
    }
    .facebook {
      top: 20%;
      left: 80%;
    }
    .floatingimg2 {
      left: 40%;
    }
  }
}

* {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
}

.dropdown-toggle {
  &::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    border: none;
    width: 10px;
    height: auto;
    margin-left: 0.4rem;
    vertical-align: middle;
  }

  &:is(:hover, .active)::after {
    content: url(escape-svg(
      'data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$primary}" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>'
    ));
  }
}

ul.benefit-list {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  li {
    padding-inline-start: 1ch;

    &::marker {
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="rgba(98, 91, 113, 1)" class="bi bi-check2" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/></svg>');
    }
  }
}

.faqs .accordion-header > button {
  gap: 4rem;
}

.search-icon {
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 99; /*this will keep the icon appearing all time, even when on input::focus*/
}
header {
  z-index: 20;
}

.hero {
  margin: 0 auto;
  // margin-top: -110px;
  border-radius: 20px;
  // background: linear-gradient(to bottom right, #fef3fd 50%, #def8fc, #dff5f8, #fff) 100% no-repeat;
  .grow-tag {
    background-color: white;
    box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
    -webkit-box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
    -moz-box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
    border-radius: 30px;
    width: fit-content;
    border: 1px solid #e4e0e8;
    .dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $primary;
    }
    p {
      margin: 0;
      line-height: 16px;
      color: $primary;
    }
  }
  .text-side {
    margin-top: 8%;
    br {
      display: none;
    }
    .hero-text {
      font-weight: 700;
      font-size: 43px;
      line-height: 48px;
      margin: 0 auto;

      span {
        color: $primary;
      }
    }
    .hero-para {
      font-size: 16px;
      line-height: 20px;
      margin: 15px auto;
    }
  }
  .getStarted-btns {
    width: 90%;
    margin: 25px auto;
    #signup-btn {
      flex-basis: 70%;
    }
    #signup-btn,
    .email-input::placeholder {
      font-weight: $headings-font-weight;
      font-size: 14px;
      line-height: 19px;
    }
    .email-input {
      border: 2px solid $primary;
      box-shadow: none;
      &::placeholder {
        color: #1f1f1f;
      }
    }
  }
  .img-side {
    padding-top: 10px;
    .img-div {
      position: relative;
    }
    .heroOne {
      max-width: 100%;
      border-radius: 10px;
      // box-shadow: 4px 3px 7px rgba(228, 224, 232, 1);
      // -webkit-box-shadow: 4px 3px 7px rgba(228, 224, 232, 1);
      // -moz-box-shadow: 4px 3px 7px rgba(228, 224, 232, 1);
    }
  }
  .tag1,
  .tag2 {
    font-size: 10px;
    line-height: 12px;
    color: $primary;
  }
  .tag1 {
    position: absolute;
    top: 25px;
    left: -18px;
    animation: tag1 2s ease-in-out infinite;
  }
  .tag2 {
    position: absolute;
    bottom: 5px;
    right: -18px;
    animation: tag2 2s ease-in-out infinite;
  }
  .tag3 {
    width: 30%;
    position: absolute;
    bottom: -80px;
    left: -20px;
    z-index: 10;
    box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
    -webkit-box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
    -moz-box-shadow: 0px 2px 4px -2px #0000000a, 0px 4px 8px -2px #00000014;
  }
}

.sales,
.grow,
.sell,
.multiPay {
  .text-side {
    // margin-top: 100px;
    h2,
    h4 {
      font-weight: 700;
      line-height: 42px;
    }
    p {
      font-size: 16px;
      line-height: 25px;
    }
    li {
      font-size: 16px;
      line-height: 23px;
      padding-top: 12px;
      margin-left: 5px;
    }
  }
  button {
    font-size: 18px;
  }
}
.sales {
  padding-top: 50px;
  .img-side {
    .img-card {
      .carousel-items {
        position: relative;
        padding-bottom: 100px;
      }
      .content {
        display: none;
      }
    }
    .logo {
      width: 100px;
      height: 100px;
      position: absolute;
      img {
        box-shadow: 5px 4px 10px rgb(223, 222, 224);
        -webkit-box-shadow: 5px 4px 10px rgb(223, 222, 224);
        -moz-box-shadow: 5px 4px 10px rgb(223, 222, 224);
      }
    }
    .logo-0 {
      opacity: 1;
      bottom: 50px;
      left: 150px;
    }
    .logo-1 {
      opacity: 1;
      bottom: 50px;
      left: 140px;
    }
    .logo-2 {
      bottom: 50px;
      animation: move2 1s linear;
      left: 100px;
    }
    .logo-3 {
      bottom: 50px;
      left: 200px;
      animation: move3 1s linear;
    }
  }
}

@keyframes move2 {
  0%,
  100% {
    left: 100px;
  }
  50% {
    left: 150px;
  }
}
@keyframes move3 {
  0%,
  100% {
    left: 200px;
  }
  50% {
    left: 150px;
  }
}

.img-side {
  img {
    border-radius: 10px;
  }
}

.grow {
  .text-side {
    margin-top: 70px;
  }
  .img-side {
    margin-top: 150px;
    padding: 0 auto;
    position: relative;
  }
  .img-card {
    box-shadow: 5px 4px 10px rgb(223, 222, 224);
    -webkit-box-shadow: 5px 4px 10px rgb(223, 222, 224);
    -moz-box-shadow: 5px 4px 10px rgb(223, 222, 224);
    .content {
      background-color: #fff;
      font-size: 16px;
      font-weight: $headings-font-weight;
      padding: 10px;
      border-radius: 20px;
      p {
        font-weight: $headings-font-weight;
        margin-top: -7px;
      }
      p:nth-of-type(3) {
        margin-top: 7px;
      }
    }
  }
  .logo {
    display: none;
  }
  .side-image {
    position: absolute;
    top: -80px;
    left: 80px;
    width: 60%;
    z-index: 3;
    img {
      box-shadow: 5px 4px 3px rgb(203, 203, 204);
      -webkit-box-shadow: 5px 4px 3px rgb(203, 203, 204);
      -moz-box-shadow: 5px 4px 3px rgb(203, 203, 204);
    }
  }
}

.sell {
  padding-top: 80px;
  .text-side {
    margin-top: 80px;
  }
  .img-side {
    position: relative;
    padding-bottom: 100px;
    img:nth-of-type(1) {
      box-shadow: 5px 4px 10px rgb(223, 222, 224);
      -webkit-box-shadow: 5px 4px 10px rgb(223, 222, 224);
      -moz-box-shadow: 5px 4px 10px rgb(223, 222, 224);
    }
  }
  .side-image {
    width: 50%;
    position: absolute;
    bottom: -30px;
    left: -5px;
  }
}

.multiPay {
  margin: 0 auto;
  // background-image: url('./assets/Bg1.png');
  background-color: #0f0f0f;
  padding: 0 20px;
  height: fit-content;
  .hero-text,
  p {
    color: #fff;
  }
  .hero-para {
    width: 90%;
  }
}

@keyframes tag1 {
  0%,
  100% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
}
@keyframes tag2 {
  0%,
  100% {
    bottom: 5px;
  }
  50% {
    bottom: 10px;
  }
}

@media all and (min-width: 750px) {
  .sales {
    padding: 50px 0;
    .img-side {
      .logo {
        width: 100px;
        height: 100px;
      }
      .logo-current .logo-0 {
        bottom: -80px;
        left: 280px;
      }
      .logo-current .logo-1 {
        bottom: -80px;
        left: 250px;
      }
      .logo-current .logo-2 {
        bottom: -80px;
        left: 200px;
      }
      .logo-current .logo-3 {
        bottom: -80px;
        left: 350px;
      }
    }
  }
  .grow {
    margin-bottom: 100px;
    .text-side {
      margin-top: 50px;
    }
    .img-side {
      margin-top: 150px;
      position: relative;
      padding: 0 auto;
    }
    .side-image {
      position: absolute;
      top: -115px;
      left: 120px;
    }
  }
  .sell {
    padding: 30px 0 150px 0;
    .text-side {
      margin-top: 70px;
    }
    .side-image {
      margin: 0 auto;
      width: 40%;
      bottom: -50px;
      left: -30px;
    }
  }
}

@media all and (min-width: 992px) {
  .hero,
  .grow,
  .sell,
  .sales,
  .multiPay {
    .text-side {
      br {
        display: none;
      }
      .hero-text {
        width: 120%;
        font-size: 55px;
        line-height: 59px;
      }
      .hero-para {
        width: 110%;
        font-size: 16px;
        margin: 0;
      }
    }
    .getStarted-btns {
      margin: 0;
      width: 120%;
      #signup-btn,
      .email-input::placeholder {
        font-size: 16px;
      }
    }
    .img-side {
      .img-div {
        position: relative;
      }
    }
    .tag1,
    .tag2 {
      font-size: 16px;
      line-height: 19px;
    }
    .tag1 {
      top: 60px;
      left: -60px;
    }
    .tag2 {
      bottom: 30px;
      right: -45px;
    }
    .tag3 {
      width: 40%;
      bottom: -55px;
      left: -50px;
    }
  }
  .sales {
    padding: 100px 0 100px 0;
  }
  .grow {
    padding-top: 40px;
  }
}
@media all and (min-width: 1000px) {
  .hero,
  .grow,
  .sell,
  .sales,
  .multiPay {
    .text-side {
      .hero-text {
        width: 119%;
      }
    }
  }
  .sales {
    padding: 50px 0;
    .text-side {
      margin: 0;
      padding-right: 20px;
    }
    .carousel-items {
      padding-bottom: 100px;
    }
    .img-side {
      .logo-0 {
        bottom: 30px;
        left: 210px;
      }
      .logo-1 {
        bottom: 30px;
        left: 210px;
      }
      .logo-2 {
        bottom: 30px;
        left: 120px;
        animation: move2 1s linear;
      }
      .logo-3 {
        bottom: 30px;
        animation: move3 1s linear;
        left: 270px;
      }
    }
  }
  @keyframes move2 {
    0% {
      left: 210px;
    }
    100% {
      left: 120px;
    }
  }
  @keyframes move3 {
    0% {
      left: 210px;
    }
    100% {
      left: 270px;
    }
  }
  .grow {
    .side-image {
      position: absolute;
      top: -90px;
      left: 100px;
    }
  }
  .sell {
    .text-side {
      margin-top: 30px;
    }
  }
  .multiPay {
    .text-side {
      margin-top: 0;
    }
  }
}

@media all and (min-width: 1150px) {
  .hero {
    position: relative;
    .text-side {
      .hero-text {
        width: 100%;
        font-size: 72px;
        line-height: 78px;
      }
      .hero-para {
        width: 90%;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
      }
    }
    .getStarted-btns {
      width: 100%;
      #signup-btn,
      .email-input::placeholder {
        font-size: 18px;
      }
    }
    .img-side {
      .img-div {
        position: relative;
        width: 100%;
      }
    }
    .tag1,
    .tag2 {
      font-size: 16px;
      line-height: 19px;
    }
    .tag1 {
      top: 60px;
      left: -60px;
    }
    .tag2 {
      right: -55px;
    }
    .tag3 {
      bottom: -60px;
      left: -70px;
    }
    .pops {
      .pop {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        position: absolute;
        z-index: 2;
        background-color: transparent;
        border: 1px solid #ceccccaf;
        transition: all 0.5s ease;
      }
      .pop:nth-of-type(1){
        top: 100px;
        left: 150px;
      }
      .pop:nth-of-type(2){
        top: 70px;
        left: 110px;
      }
      .pop:nth-of-type(3){
        top: 50px;
        left: 170px;
      }
      .pop:nth-of-type(4){
        top: 10px;
        left: 210px;
      }
      .pop:nth-of-type(5) {
        bottom: 100px;
        left: 680px;
      }
      .pop:nth-of-type(6) {
        bottom: 140px;
        left: 710px;
      }
      .pop:nth-of-type(7) {
        bottom: 180px;
        left: 680px;
      }
      .pop:nth-of-type(8) {
        bottom: 190px;
        left: 625px;
      }
      .pop:hover {
        cursor: pointer;
        background-color: $primary;
        box-shadow: 2px 2px 2px rgb(207, 207, 207);
        -webkit-box-shadow: 2px 2px 2px rgb(207, 207, 207);
        -moz-box-shadow: 2px 2px 2px rgb(207, 207, 207);
        opacity: 0.5;
      }
    }
  }
  .sales,
  .grow,
  .sell,
  .multiPay {
    .text-side {
      h2 {
        font-weight: 700;
        line-height: 65px;
      }
      p {
        font-size: 18px;
        line-height: 29px;
      }
      li {
        font-size: 18px;
        line-height: 23px;
        padding-top: 14px;
      }
    }
  }
  .sales {
    .text-side {
      margin: 0;
      padding-right: 20px;
    }
  }
  .grow {
    .text-side {
      margin-top: 140px;
      padding: 0 10px;
    }

    .side-image {
      position: absolute;
      top: -115px;
      left: 120px;
    }
  }
  .sell {
    padding: 30px 0 150px 0;
    .text-side {
      margin-top: 20px;
    }
    .side-image {
      width: 50%;
      position: absolute;
      bottom: -60px;
      left: -80px;
    }
  }

  .discount-badge {
    background: #0f0f0f !important;
    border-radius: 50px 0.375rem 0px 50px !important;
  }
}
