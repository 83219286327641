@import url('https:///fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  overflow-x: hidden;
  font-size: 14px;
}

:root {
  --primary: #8a50fc;
}

.btn {
  padding: 10px 2rem 10px 2rem !important;
  font-weight: 500 !important;
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  outline: none !important;
}

a,
a:hover {
  color: var(--primary);
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

a.text-primary {
  color: var(--primary) !important;
}

.mobile {
  display: none;
}

@media (max-width: 480px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .logo-box {
    text-align: center;
  }

  .text-center-mobile {
    text-align: center !important;
  }
}

button,
button:active,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.button-disabled {
  opacity: 0.5;
}

.tj__header-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  background-color: #ffffff;
}

.tj-fixed {
  position: fixed !important;
}

.vn-guides-sidebar {
  list-style: numbers;
}

.vn-guides-sidebar li a {
  color: #000000;
  text-decoration: underline;
}

/* ------------- */
.tj-form-response {
  position: fixed;
  width: 100%;
  top: 50px;
  left: 0;
  border-radius: 10px;
  z-index: 1000;
  text-align: center;
  display: none;
}

.tj-form-response p {
  color: #fff;
  padding: 10px;
  width: 450px;
  border-radius: 6px;
  margin: auto;
  font-weight: 500;
  background-color: dodgerblue;
}

@media (max-width: 480px) {
  .tj-form-response {
    width: 90%;
    left: 5%;
  }
  .tj-form-response p {
    width: 100% !important;
  }
}

.tj-form-response p.error {
  background-color: #e12c2c;
}

.tj-form-response p.success {
  background-color: #11d172;
}
/* ------------- */
.tj-right {
  position: fixed;
  width: 50vw;
  position: absolute;
  right: 0px;
  height: auto;
}

.tj-box {
  width: 20rem;
  height: auto;
}

.tj-box h1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #000000;
}

.tj-box h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  margin-top: -5px;
  color: #000000;
}

.tj-google {
  padding: 16px;
  gap: 10px;
  width: 19.9rem;
  height: 52px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 4px;
}

.tj-google span {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #000000;
}

.tj-line span {
  width: 200px;
  background-color: #bfbfbf;
  height: 0px;
  border: 1px solid #bfbfbf;
  transform: rotate(-180deg);
  margin-bottom: -5px;
}

.tj-form label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}

.tj-form input,
select {
  display: flex;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #c8c1d1;
  border-radius: 4px;
  box-sizing: border-box;
  height: 52px;
}

.tj-form ::placeholder {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #d9d9d9;
  margin-top: -2rem;
}

.tj-description::placeholder {
  margin-right: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #d9d9d9;
}

.tj-icon1 {
  position: absolute;
  top: 83%;
  right: 4%;
  cursor: pointer;
}

.tj-icon3 {
  position: absolute;
  top: 28%;
  right: 4%;
  cursor: pointer;
}

.tj-icon4 {
  position: absolute;
  top: -133%;
  left: 38%;
  height: 41px;
}

.tj-icon-check {
  position: relative;
  height: 38px;
  top: 7px;
  right: 13px;
}

.tj-box1 {
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 50px;
  background: #8a50fc;
  border-radius: 4px;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 8px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

.tj-box2 {
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 19.9rem;
  height: 2.5rem;
  background: #fff;
  border: 1px solid #8a50fc;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 8px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(0, 0, 0);
}

#tj-forgottenpassword {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #8a50fc;
  margin-top: 13px;
  text-decoration: none;
}

.tj-acct {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #1f1f1f;
}

.tj-acct div {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #8a50fc;
}

.tj-terms {
  margin-left: 3.3rem;
  font-family: 'Raleway';
  font-style: normal;
  gap: 3px;
  margin-top: 4rem;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.tj-circle {
  background-color: rgb(240, 239, 239);
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  margin-left: 5.5rem;
  margin-top: 2rem;
}

.tj-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tj-modal-content {
  padding: 51px 50px;
  grid-gap: 8px;
  gap: 8px;
  width: 589px;
  height: 196px;
  background: #ffffff;
  border-radius: 8px;
}

.tj-body-forget {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.tj-forget-email span {
  color: #8a50fc;
}

.tj-slider {
  width: 114px;
  height: 4.5px;
  background: #d9d9d9;
  border-radius: 8px;
}

.tj-slider-selected {
  width: 114px;
  height: 4.5px;
  background: #8a50fc;
  border-radius: 8px;
}

/* -----------LOGO */
.imageContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16;
}

.imagePath {
  position: relative;
  display: inline-flex;
  border-radius: 2;
  /* border: 1px solid #eaeaea; */
  margin-bottom: 8;
  margin-right: 8;
  width: 100%;
  height: 100%;
  /* padding: 4px; */
  box-sizing: border-box;
}

.tj-upload-img {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  position: absolute;
  bottom: 0rem;
  left: 2rem;
  object-fit: cover;
}

/* --------------- */

/* -------------Landing Page(LP)--------- */
/* *{
  background-color:rgb(248, 248, 248);
} */

.ld-left-header {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.005em;
  color: #1f1f1f;
}

.ld-header-button {
  border: none;
  background-color: #fff;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  padding: 10px 20px;
  background: #8a50fc;
  border: 1px solid #8a50fc;
  border-radius: 6px;
  text-align: center;
  align-items: center;
  text-decoration: none;
}

.ld-header-button:hover {
  color: #fff;
}

#ld-header-links {
  color: #000000;
  text-decoration: none;
}

#ld-header-links:hover {
  color: #8a50fc;
  text-decoration: underline;
}

.ld-header-title h1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 70px;
  color: #1f1f1f;
}

.ld-header-title p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  color: #1f1f1f;
}

.ld-button-started {
  gap: 16px;
  color: #fff;
  width: 341px;
  height: 49px;
  background: #8a50fc;
  border: 1px solid #8a50fc;
  border-radius: 8px;
  margin-top: 12px;
}

.lg-wavy {
  height: 20px;
  position: absolute;
  top: 9%;
  right: 64%;
}

.ld-arrow {
  height: 425px;
  width: 109px;
  position: absolute;
  bottom: 24%;
  right: 8%;
}

.ld-taooja1 {
  height: 50rem;
  width: 61rem;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 50%;
}
.ld-h-line {
  position: absolute;
  width: 100vw;
  height: 0px;
  bottom: -66%;
  border: 1px solid #eeeeee;
}

.ld-v-line1 {
  position: absolute;
  right: 63%;
  bottom: -11%;
  width: 750px;
  height: 0px;
  border: 1px solid #eeeeee;
  transform: rotate(90deg);
}

.ld-v-line2 {
  position: absolute;
  left: 63%;
  bottom: -11%;
  width: 750px;
  height: 0px;
  border: 1px solid #eeeeee;
  transform: rotate(90deg);
}

.ld-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #f3eeff;
  margin-top: 2rem;
}

.ld-subhead h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  color: #1f1f1f;
}

.ld-boxes {
  height: auto;
}

.ld-p-1 p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: end;
  color: #1f1f1f;
}

.ld-p-2 p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  text-align: start;
  color: #1f1f1f;
}

.ld-cicle1 {
  width: 16rem;
  vertical-align: sub;
  margin-left: 125px;
}

.ld-cicle2 {
  width: 8rem;
  vertical-align: sub;
  /* margin-left: 89px; */
}

.ld-sub2-h1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  color: #1f1f1f;
  margin-top: 5rem;
}

#ld-3box-1 {
  font-family: 'Raleway';
  font-style: normal;
  margin-top: 2rem;
  width: 23rem;
  height: 26rem;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 8px;
}

.ld-sub2-h3 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #1f1f1f;
  margin-bottom: 4rem;
}

.ld-big-taooja {
  text-align: center;
  margin-top: 2rem;
}

.ld-sub-taooja {
  object-fit: cover;
  width: 19rem;
}

.ld-3box-11 img {
  object-fit: cover;
  width: 12rem;
  height: 9rem;
  margin-bottom: 1rem;
  align-self: center;
}

.ld-box-link {
  color: #8a50fc;
}

.ld-4box-1 {
  font-family: 'Raleway';
  font-style: normal;
}

.ld-4box-1 img {
  width: 159px;
  margin-bottom: 1rem;
}

#ld-sub2-head {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #1f1f1f;
  margin-top: 3rem;
}

.ld-image-box {
  width: 66rem;
  height: 14rem;
  border-radius: 8px;
  position: relative;
  text-align: center;
  padding: 59px;
  background-color: #8a50fc;
}

.ld-last-image {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ld-image-box p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #fff;
}
/* html,body{
  margin: 0px;
} */
.ld-footer-body {
  font-family: 'Raleway';
  font-style: normal;
  width: 100vw;
  height: 29rem;
  left: -4px;
  top: 6133px;
  background: #f3eeff;
}

.ld-footer-line {
  width: 99.7vw;
  height: 0px;
  left: -13px;
  top: 298rem;
  border: 1px solid #ffffff;
  position: absolute;
}

.ld-footer-link {
  text-decoration: none;
  color: #1f1f1f;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.ld-footer-link:hover {
  color: #8a50fc;
}

.ld-footer-h {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #1f1f1f;
}

/* MOBILE DESIGN */
.tj__header {
  background-color: #fff;
}

.tj_sm_button1 {
  border: none;
  background-color: #8a50fc;
  color: #fff;
  border-radius: 8px;
  padding: 10px 2rem 10px 2rem;
}

.tj_mb_button2 {
  border: none;
  background-color: #8a50fc;
  color: #fff;
  border-radius: 8px;
  padding: 12px 1rem 12px 1rem;
}

.tj_mb-h1 {
  font-size: 23px;
}

.tj_mb_wavy {
  position: absolute;
  height: 9px;
  top: 75px;
  right: 90px;
  display: none;
}

.tj_mb-p {
  line-height: 19px;
  font-size: 14px;
}

.mb-taooja1 {
  height: 12rem;
  width: 18rem;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 58%;
}

.mb-circle {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #f3eeff;
  margin-top: 3rem;
}

.mb-arrow {
  height: 281px;
  width: 51px;
  position: absolute;
  bottom: 24%;
  right: 4%;
}

.mb-h-line {
  position: absolute;
  width: 100vw;
  height: 0px;
  top: 101%;
  border: 1px solid #eeeeee;
}

.mb-v-line1 {
  position: absolute;
  right: 58%;
  bottom: 22%;
  width: 269px;
  height: 0px;
  border: 1px solid #eeeeee;
  transform: rotate(90deg);
}

.mb-v-line2 {
  position: absolute;
  left: 58%;
  bottom: 22%;
  width: 269px;
  height: 0px;
  border: 1px solid #eeeeee;
  transform: rotate(90deg);
}

.mb-subtitle {
  text-align: center;
  font-weight: 500;
}

.mb-p-1 p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #1f1f1f;
}

.mb-cicle1 {
  width: 11rem;
}

#mb-3box-1 {
  font-family: 'Raleway';
  font-style: normal;
  margin-top: 2rem;
  width: 16rem;
  height: 21rem;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 8px;
}

.card-title {
  font-family: 'Raleway';
  font-size: 28;
  font-weight: 700;
  line-height: 34px; /* 121.429% */
  letter-spacing: -0.56px;
}
.card-title-text {
  font-family: 'Raleway';
  color: #101828;
  font-size: 28;
  font-weight: 700;
  line-height: 42px; /* 121.429% */
  letter-spacing: -0.56px;
  font-style: normal;
}
.card-body {
  color: #d9d9d9 !important;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.hero-card-body {
  color: black;
  font-size: 20px;
  font-family: 'Raleway';
  font-weight: 400;
  line-height: 30px;
  word-wrap: 'break-word';
}
.hero-card-title {
  color: #141218;
  font-size: 60px;
  font-family: 'Raleway';
  font-weight: 700;
  line-height: 72px;
  word-wrap: 'break-word';
  padding-bottom: 5px !important;
}
.card-body-text {
  color: #475467 !important;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.card-title-text {
  color: #101828 !important;
  font-family: 'Raleway';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 150% */
  letter-spacing: -0.56px;
}

.box-section {
  padding-top: 60px;
  padding-bottom: 60px;

  display: flex;

  gap: 20px;
}
.card-button {
  font-family: 'Raleway';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px;
  color: #000;
}
.card-button2 {
  font-family: 'Raleway';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px;
  color: #fff;
  background: #8a50fc;
}

@media screen and (max-width: 766px) {
  .box-section {
    display: grid;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.mb-3box-11 img {
  object-fit: cover;
  width: 7rem;
  height: 5rem;
  margin-bottom: 1rem;
  align-self: center;
}

.mb-3box-11 h4 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
}

.mb-3box-11 p {
  text-align: center;
  font-size: 10px;
  margin-top: 1rem;
}

.mb-boxes {
  font-family: 'Raleway';
  font-style: normal;
  margin-top: 2rem;
  width: 13rem;
  height: 14rem;
  background-color: #fff;
  border-radius: 8px;
}

#mb-boxx img {
  object-fit: cover;
  width: 6rem;
  height: 4rem;
  align-self: center;
}

#mb-boxx h4 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
}

#mb-boxx p {
  text-align: center;
  font-size: 10px;
}

.mb-image-box {
  width: 100%;
  padding: 30px 30px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  background-color: #8a50fc;
}

.mb-image-box p {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.mb-footer-body {
  font-family: 'Raleway';
  font-style: normal;
  height: 62rem;
  width: 100vw;
  background: #f3eeff;
}

.tj__mobile-menu .tj__mobile-menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  height: 80vh;
}

.tj__mobile-menu .tj__mobile-menu__list .tj__menu {
  display: block;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}

.tj__mobile-menu .tj__mobile-menu__list .tj__menu .tj__menu-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0px;
}

.tj__mobile-menu .tj__mobile-menu__list .tj__menu .tj__menu-item span {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.tj__mobile-menu .tj__mobile-menu__list .tj__menu .tj__menu-item span.active {
  color: #8a50fc;
}

.tj__menu-icon {
  color: #292d32;
}

.tj__menu-btns {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.tj__menu-btn {
  border-radius: 4px;
  display: block !important;
  padding: 12px 18px !important;
  margin-bottom: 15px;
}

.vn-guides-sidebar {
  margin-top: 60px;
  width: 100% !important;
  list-style: none;
}

.vn-guides-sidebar .vn-item {
  font-weight: 500;
  font-size: 14px;
}

.vn-guides-sidebar .vn-item.active {
  color: #8a64d6 !important;
}

.vn-guides-sidebar .vn-item .vn-sub-list {
  padding: 0px 10px;
  height: 0px;
  overflow: hidden;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.vn-guides-sidebar .vn-item.active .vn-sub-list {
  height: auto;
  border: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.vn-guides-sidebar .vn-item .vn-sub-list li {
  font-size: 14px;
  font-weight: 400;
  font-family: sans-serif;
  color: #000;
  padding: 5px 0px;
}

.vn-guides-title {
  font-size: 70px;
  font-weight: 900;
}

.vn-guides-sub-title {
  font-size: 40px;
  font-weight: 900;
  border-left: 5px solid #8a64d6;
  margin-top: 13px;
  padding-left: 20px;
}

@media (max-width: 480px) {
  .vn-guides-title {
    font-size: 50px;
    font-weight: bold;
  }
  .vn-guides-sub-title {
    font-size: 27px;
  }
}

.vn-guides-desc {
  margin-top: 40px;
  font-size: 15px;
  font-weight: 400;
}

.vn-guides-ul {
  list-style-type: disc;
  padding-left: 20px;
}

.vn-guides-ul li {
  font-size: 16px !important;
  margin-bottom: 15px;
}

.guide-icon {
  padding: 7px 8px;
  background-color: #f8f5ff;
  border-radius: 5px;
}

.category-banner {
  width: 100%;
  height: 572px;
  border-radius: 32px;
}

.feature-badge.storefront {
  left: -100px;
  top: 140px;
}

.feature-badge.finance {
  left: -50px;
  top: 340px;
}

.feature-badge.insight {
  right: -50px;
  top: 140px;
}

.feature-badge.support {
  right: 0px;
  top: 320px;
}

.feature-badge.sales {
  left: 43%;
  bottom: -10px;
}

.sub-cat {
  width: 100%;
  height: 250px;
  overflow: hidden;
  border-radius: 150px;
}

.sub-cat-overlay {
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #00000090;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  bottom: 0;
}

.sub-cat:hover .sub-cat-overlay {
  height: 100%;
}

.offering-title {
  color: #000;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.sub-cat-name {
  font-size: 40px;
}

.benefit-card {
  background-color: #0f0f0f;
  overflow: hidden;
}

.benefit-card img {
  width: 100%;
}

.section-title {
  font-size: 60px;
}

@media (max-width: 480px) {
  .category-banner {
    height: 397px;
  }

  .feature-badge.storefront {
    left: -10px;
    top: 40px;
  }

  .feature-badge.finance {
    left: 0px;
    top: 200px;
  }

  .feature-badge.insight {
    right: 0;
    top: 100px;
  }

  .feature-badge.support {
    right: 0;
    top: 260px;
  }

  .feature-badge.sales {
    left: 35%;
    bottom: -10px;
  }

  .feature-img {
    width: 25px;
  }

  .offering-title {
    font-size: 23px;
  }

  .sub-cat {
    width: 100%;
    height: 60px;
  }

  .sub-cat.others {
    height: 78px;
  }

  .sub-cat-name {
    font-size: 16px;
  }

  .section-title {
    font-size: 33px;
  }
}
